import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Monam" />
    <div className="text-xs text-center md:text-lg">
      <p>
        From <strong>Ubrique</strong> (Spain), right in the middle of the
        world´s leading city on <strong>leather craftsmanship</strong> we work
        to materialize your <strong>project, idea or dream</strong>.
      </p>

      <p>We strive to improve, transform or develop further your product.</p>

      <div>
        <ul>
          <li>A vanguardist designed card holder?</li>
          <li>A minimalistic bag without stitches?</li>
          <li>A box to safeguard a great story?</li>
          <li>A personal accessory as second skin?</li>
        </ul>
      </div>
      <p>Just let us know!</p>

      <p>
        Contact us
        <br />
        and express yourself in leather…
        <br />
        <a href="mailto">monam@monam.es</a>
      </p>
    </div>
  </Layout>
)

export default IndexPage
